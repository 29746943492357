<script>
import Multiselect from 'vue-multiselect'
import {getDropDownElementsByTypeApi,fetchLifebasesApi} from "@/api/common";
import Swal from "sweetalert2";
export default {
    components:{
        Multiselect
    },
    props:['add','edit','activity'],
    data() {
        return {
            camps:[],
            disable:true,
            activityForm:{
                lifebase:"",
                date:"",
                status:'draft',
                activities:[]
            },
            units:[]
        }
    },
    created(){
        this.listActivities();
        this.getLifebasesList();
        this.getUnitList();
    },
    methods:{
        listActivities(){
            getDropDownElementsByTypeApi('hse_trash')
            .then(res=>{
                this.activityForm.activities=[];
                res.data.original.list.forEach(element => {
                    this.activityForm.activities.push({
                        id:element.id,
                        name:element.designation,
                        unit:"",
                        qty:"",
                        source:"",
                        location:"",
                        comments:""
                    })
                })
                // this.activityForm.activities = res.data.original.list;
            })
            .catch(error => console.log(error))
        },
        getUnitList(){
            getDropDownElementsByTypeApi('uom')
            .then(res=>{
                this.units = res.data.original.list
                // this.activityForm.activities = res.data.original.list;
            })
            .catch(error => console.log(error))
        },
        getLifebasesList(){
            fetchLifebasesApi()
            .then(res=>{
                this.camps = res.data.original.list;
            })
            .catch(error => console.log(error))
        },
        resetForm(){
            this.activityForm.activities=[];
            this.activityForm.lifebase=[];
            this.activityForm.date="";
            this.$emit('resetForm',true);
            this.listActivities();
            this.disable=true;
        },
        saveActivity(){
            this.$http.post('/ops/hse/trashes/store',this.activityForm)
            .then(res => {
                Swal.fire('Réussi','Les activités ont été enregistré avec succès','success')
                this.resetForm();
                // this.listActivities();
                this.$emit('saved',true);
            })
            .catch(error =>{
                console.log(error);
                Swal.fire('Erreur','Une erreur s\'est produite, veuillez réessayer','success');
            });
        },
        savePendingActivity(){
            this.activityForm.status = 'pending';
            this.saveActivity();
        },
        onUpdate(){
            this.$http.post('/ops/hse/trashes/update',this.activityForm)
            .then(res => {
                Swal.fire('Réussi','Les activités ont été enregistré avec succès','success')
                this.resetForm();
                this.listActivities();
                this.$emit('saved',true);
            })
            .catch(error =>{
                console.log(error);
                Swal.fire('Erreur','Une erreur s\'est produite, veuillez réessayer','success');
            });
        },
        onStatus(status){
            var _this = this;
            Swal.fire({
                title: 'Êtes-vous sûr?',
                text: "Cette action est irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui'
                }).then((result) => {
                if (result.isConfirmed) {
                    _this.$http.post('/ops/hse/trashes/changeStatus',{id:_this.activityForm.id,status:status})
                    .then(res => {
                        _this.resetForm();
                        _this.$emit('saved',true);
                        Swal.fire('Réussi!',res.data.message,'success')
                    })
                    .catch(error => console.log(error))
                }
            })
            
        }
    },
    watch:{
        add:{
            handler(val){
                if(val) this.disable = false;
                else this.disable = true
            }
        },
        edit:{
            handler(val){
                if(val) this.disable = false;
                else this.disable = true
            }
        },
        activity:{
            handler(val){
                this.activityForm.activities = [];
                if(val && val[0].id){
                    this.activityForm.id = val[0].id;
                    this.activityForm.uuid = val[0].uuid;
                    this.activityForm.lifebase = val[0].camp;
                    this.activityForm.date = val[0].exec_date;
                    val[0].trash_items.forEach(element=>{
                        this.activityForm.activities.push({
                            id:element.id,
                            trash_type_id:element.trash_type_id,
                            name:element.trash_type.designation,
                            unit:element.uom,
                            qty:element.quantity,
                            location:element.dump,
                            source:element.source,
                            comments:element.comments
                        })
                    })
                }
            }
        }
    }
}
</script>
<template>
    <div class="row">
        <div class="col">
            <div class="row m-2">
                <div class="col text-right">
                    <button v-if="activity[0] && activity[0].status == 'draft'" @click="onStatus('pending')" class="btn btn-info mr-1">Envoyer pour validation</button>
                    <button v-if="activity[0] && activity[0].status == 'pending' && $can('hse_trash_approve')" @click="onStatus('approved')" class="btn btn-success mr-1">Valider</button>
                    <button v-if="activity[0] && activity[0].status == 'pending' && $can('hse_trash_reject')" @click="onStatus('rejected')" class="btn btn-danger">Rejeter</button>
                </div>
            </div>
            <div class="row m-3">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label for="formrow-password-input">Base de vie:</label>
                            <multiselect :disabled="disable" v-model="activityForm.lifebase" :select-label="''"  track-by="id" label="name" placeholder="Select one" :options="camps" :allow-empty="false">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                            </multiselect>
                            
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label for="formrow-inputCity">Date *</label>
                            <input
                            type="date"
                            v-model="activityForm.date"
                            class="form-control"
                            name=""
                            id=""
                            :disabled="disable"
                            />
                        </div>
                    </div>
                
            </div>
            <div class="row m-3">
                <div class="col">
                    <fieldset :disabled="disable">
                            <div
                            v-for="(activity,i) in activityForm.activities"
                            :key="i"
                            class="row"
                            >
                                <div class="form-group col-lg">
                                    <label for="message">Type de Déchèt ou Détritus</label>
                                    <input
                                    id="subject"
                                    disabled
                                    readonly
                                    v-model="activity.name"
                                    type="text"
                                    class="form-control"
                                    />
                                </div>
    

                                <div class="form-group col-lg">
                                    <label for="message">Unité</label>
                                    <multiselect v-model="activity.unit" :select-label="''"  track-by="id" label="designation" placeholder="Select one" :options="units" :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.designation }}</strong></template>
                                    </multiselect>
                                </div>
                                <div class="form-group col-lg">
                                    <label for="message">Quantité</label>
                                    <input
                                    id="subject"
                                    v-model="activity.qty"
                                    type="text"
                                    class="form-control"
                                    />
                                </div>
                                <div class="form-group col-lg">
                                    <label for="message">Source</label>
                                    <input
                                    id="subject"
                                    v-model="activity.source"
                                    type="text"
                                    class="form-control"
                                    />
                                </div>
                                <div class="form-group col-lg">
                                    <label for="message">Déstination</label>
                                    <input
                                    id="subject"
                                    v-model="activity.location"
                                    type="text"
                                    class="form-control"
                                    />
                                </div>
                                <div class="form-group col-lg">
                                    <label for="message">Observation</label>
                                    <input
                                    id="subject"
                                    v-model="activity.comments"
                                    type="text"
                                    class="form-control"
                                    />
                                </div>
                            </div>
                    </fieldset>
                    <div class="row">
                        <div class="col text-right">
                            <button v-if="!disable" class="btn btn-danger mr-1" @click="resetForm">Annuler</button>
                            <button v-if="add" class="btn btn-primary mr-1" @click="saveActivity()">Enregister</button>
                            <!-- <button v-if="add" class="btn btn-info mr-1" @click="savePendingActivity()">Enregister</button> -->
                            <button v-if="edit" :disabled="activity[0].status == 'approved' || !$can('edit_trash')" @click="onUpdate()" class="btn btn-warning" >Modifier</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>